<template> 
    <v-layout justify-center class="py-2" fill-height>   
        <mbs-page-spinner v-if="!Joined_SaleOrders" />  
        <span v-else>  
            <span  >   
                <mbs-epson-display-temp1  
                    :show="true"
                    :loading="inputLoading"
                    :print_request="DAY_SHIFT_PRINT_REQUEST"/>  
            </span>

        </span>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SALE-ITEMS-TEMPLATE-1'
    export default {
        props:['data','report_type','document_type','title','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                DAY_SHIFT_PRINT_REQUEST:null,
            } 
        }, 
        mounted(){ 
            this.EPSON_SALE_ORDER_REQUEST({
                report_type:this.report_type,
                document_type:this.document_type,
                data:{
                    ...this.data,
                    sale_orders:this.Joined_SaleOrders,
                     
                }
            }) 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
             
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            SalesOrders(){ 
                let data = this.data.sale_orders 
                return  data
            },
            Joined_SaleOrders(){ 
                let sales = this.SalesOrders  
                if(!sales){return null}
                let join = this.MBS.actions.JOIN_SALE_ORDERS(sales)  
                return join
            }, 
            Company(){ 
                let item = this.Joined_SaleOrders
                return item?item.company:null
            },  
            
             
        },
        methods:{
            async EPSON_SALE_ORDER_REQUEST(payload){ 
                try {   
                    console.log(payload,'payload.. ...ii----------');
                    this.inputLoading = true
                    let print_order = await this.MBS.actions.EPSON_DAY_ITEM_REQUEST({
                        ...payload,
                        print_title:this.title,
                        printer:{
                            paper_size:38
                        }
                    }) 
                    console.log(print_order,'print_order.......i..');
                    this.DAY_SHIFT_PRINT_REQUEST = print_order?print_order.data:null
                    console.log(this.DAY_SHIFT_PRINT_REQUEST,'DAY_SHIFT_PRINT_REQUEST......**.------.');
                    this.inputLoading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_SALE_ORDER_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },    
        },
        watch:{
            mbs_dialog(value){ 
            },  
            Joined_SaleOrders(value){ 
                this.EPSON_SALE_ORDER_REQUEST({
                    report_type:this.report_type,
                    document_type:this.document_type,
                    data:{
                        sale_orders:value,
                        day_shift:this.data?.day_shift, 
                    }
                }) 
            },   
        } 
    }
</script>
